import logo from './logo.svg';
import './App.css';
import './App.scss';
import {GlowGlobe} from "./glowglobev2/GlowGlobe";
import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import {Button} from "primereact/button";

function App() {
  PrimeReact.ripple = true;

  return (
      <div>
          <div style={{ padding: '5px' }}>
              {/* eslint-disable-next-line no-restricted-globals */}
            <Button label="Back to GARDIAN" onClick={() => {history.back()}} icon="fas fa-arrow-left" />
          </div>
          <GlowGlobe />
      </div>
  );
}

export default App;
