import axios from 'axios';
import seaMap from '../glowglobev2/maps/earthseas10km.geojson';
import * as turf from "@turf/turf";

export default class ProductService {

    async getPresignedTiffURL(id,type){

        let config = {
            method: 'get',
            url: 'https://gardian-api.scio.services/api/spatial/'+type+'/'+id,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios(config)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                return error.response
            });
    }

    async getSpatialData(request){
        let config = {
            method: 'post',
            url: 'https://gardian-api.scio.services/api/spatial',
            headers: {
                'Content-Type': 'application/json'
            },
            data:request
        };

        return axios(config)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                return error.response
            });
    }

    async isSea(request){
        let geojson = {
            type:"Feature",
            properties:{},
            geometry:{
                coordinates:[
                    request.lat_lng.lng,
                    request.lat_lng.lat
                ],
                type:"Point"
            }
        }
        return axios.get(seaMap).then(
            (item) =>{
                return turf.inside(geojson, item.data.geometries[0]);
            }
        )
    }

    async getGADMPolygon(request) {
        let administrationLevel = "administration_level_"+request.level;
        let config = {
            method: 'get',
            url: 'https://gardian-api.scio.services/api/gadm/'+administrationLevel+"/"+request.lat_lng.lat+"/"+request.lat_lng.lng,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return  axios(config)
            .then(function (response) {
                const gadmData = response.data.features.map(
                    (item)=>{
                        console.log(item)
                        let title = '';
                        if(item.properties.NAME_1){
                            title = title+item.properties.NAME_1;
                        }
                        if(item.properties.NAME_2){
                            title = title+" / "+item.properties.NAME_2;
                        }
                        if(item.properties.NAME_3){
                            title = title+" / "+item.properties.NAME_3;
                        }

                        let color = '';
                        if(administrationLevel === "administration_level_1"){
                            color = "#00eaff";
                        }else if(administrationLevel === "administration_level_2"){
                            color = "#00ffb2";
                        }else if(administrationLevel === "administration_level_3"){
                            color = "#7300ff";
                        }

                        let newItem = {};
                        newItem.opacity = 0.5;
                        newItem.id = Math.floor(Math.random() * 100);
                        newItem.title = title;
                        newItem.layer = item;
                        newItem.color = color;

                        return newItem;
                    }
                )

                return gadmData;
            })
            .catch(function (error) {
                return error.response
            });
    }

    async getSpatialDatasets() {
        let config = {
            method: 'get',
            url: 'https://gardian-api.scio.services/api/spatial',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return axios(config)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                return error.response
            });
    }

    async getBasemaps(){
        let basemaps = [
            {
                tileUrl:'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                title:'OpenStreet Map Mapnik',
                attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                options:{},
                thumbnail:'OpenStreetMapMapnik.png',
                id:'OpenStreetMapMapnik',
                default: true,
                basemap: true
            },
            {
                tileUrl:'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}',
                title:'USGS US Imagery Topo',
                attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>',
                options:{},
                thumbnail:'USGSUSImageryTopo.png',
                id:'USGSUSImageryTopo',
                default: false,
                basemap: true
            },
            {
                tileUrl:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                title:'Esri World Topo Map',
                attribution:'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                options:{},
                thumbnail:'EsriWorldTopoMap.png',
                id:'EsriWorldTopoMap',
                default: false,
                basemap: true
            }
        ]

        return basemaps;
    }


    getProductsSmall() {
        return axios.get('assets/demo/data/layers_test.json').then((res) => res.data.data);
    }

    getProducts() {
        return axios.get('assets/demo/data/products.json').then((res) => res.data.data);
    }
}
