import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import TerracottaService from "../service/TerracottaService";
import {Menu} from "primereact/menu";
import turf from "turf";
import {Toast} from "primereact/toast";

export const PolygonActionComponent = (props) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);

    const op = useRef(null);
    const toast = useRef(null);
    const navigationMenu = useRef(null);

    useEffect(() => {
        if(props.data){
            setData(props.data);
        }
    },[props.data])


    const overlayActionBodyTemplate = (event,rowData) => {
        const layerDetails = event;
        return (
            <>
                <Button type="button"
                        icon="fad fa-download"
                        className="p-mr-2"
                        tooltip={"Download the data of "+layerDetails.title+" " +
                        "included by "+rowData.title}
                        tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                        onClick={() =>{downloadData(rowData,layerDetails)}}>

                </Button>
                <Button type="button"
                        icon="fad fa-chart-line"
                        className="p-mr-2"
                        tooltip={"Check the statistics of "+layerDetails.title+" included by "+rowData.title}
                        tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                        disabled
                >
                </Button>
            </>


        );
    }
    const downloadData = (rowData,layerDetails) =>{

        let dataRequest = {
            s3_bucket : "scio-terracotta",
            file_name : "data/"+layerDetails.s3_link,
            ROI_file_url : null,
            ROI : rowData.layer
        }

        setLoading(true);
        const terracottaService = new TerracottaService();
        terracottaService.getSpatialData(dataRequest).then(
            (item) => {
                if(item.status === 500){
                    toast.current.show({severity:'error', summary: 'Download Spatial Data', detail:'A unexpected error has occurred', life: 3000});
                }else{
                    const url = item.data.output_file;
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        layerDetails.id+'.tif',
                    );
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    toast.current.show({severity:'success', summary: 'Download Spatial Data', detail:'The data are travelling to your machine', life: 3000});
                }
                setLoading(false);
            }
        ).catch(
            (error)=>{
                setLoading(false);
            })
    }


    const zoomToPolygon = (e,rowData) =>{
        let tempLayerAction = {
            'action':'zoom',
            'id':rowData.id
        }
        props.action(tempLayerAction);

    }

    const bringToFront = (e,rowData) =>{
        let tempLayerAction = {
            'action':'bringToFront',
            'id':rowData.id
        }
        props.action(tempLayerAction);
    }

    const bringToBack = (e,rowData) =>{
        let tempLayerAction = {
            'action':'bringToBack',
            'id':rowData.id
        }
        props.action(tempLayerAction);
    }

    const removeCustomPolygon = (e,rowData) =>{
        let tempLayerAction = {
            'action':'remove',
            'id':rowData.id
        }
        props.action(tempLayerAction);
    }

    const computeGeojson = (computationType,data) =>{
        let newPolygon = {};
        if(computationType === 'convex_hull'){
            let hull = turf.convex(data.layer);
            newPolygon.type = 'convex_hull';
            newPolygon.sourcePolygon = data;
            newPolygon.layer = hull;
        }else if(computationType === 'envelope'){
            let envelope = turf.envelope(data.layer);
            newPolygon.type = 'envelope';
            newPolygon.sourcePolygon = data;
            newPolygon.layer = envelope;
        }else if(computationType === 'bbox'){
            let bbox = turf.bbox(data.layer);
            let bboxPolygon = turf.bboxPolygon(bbox);
            newPolygon.type = 'bbox';
            newPolygon.sourcePolygon = data;
            newPolygon.layer = bboxPolygon
        }

        let tempLayerAction = {
            'action':'add',
            'geojson':newPolygon
        }
        props.action(tempLayerAction);
    }

    let items = [
        {
            label: 'Navigation',
            items: [
                {
                    label: 'Zoom to Polygon',
                    icon: 'fad fa-search-location',
                    command: () => {
                        zoomToPolygon('',props.data.rowData)
                    }
                },
                {
                    label: 'Sent Polygon to Back',
                    icon: 'fad fa-send-back',
                    command: () => {
                        bringToBack('',props.data.rowData)
                    }
                },
                {
                    label: 'Bring Polygon to Front',
                    icon: 'fad fa-bring-front',
                    command: () => {
                        bringToFront('',props.data.rowData)
                    }
                }
            ]
        },
        {
            separator:true
        },
        {
            label: 'Computation',
            items: [
                {
                    label: 'Convex Hull',
                    icon: 'fad fa-hexagon',
                    command: () => {
                        computeGeojson('convex_hull',props.data.rowData)
                    }
                },
                {
                    label: 'Envelope',
                    icon: 'fad fa-rectangle-landscape',
                    command: () => {
                        computeGeojson('envelope',props.data.rowData)
                    }
                },
                {
                    label: 'Bounding Box',
                    icon: 'fad fa-square',
                    command: () => {
                        computeGeojson('bbox',props.data.rowData)
                    }
                }
            ]
        }
    ];

    return (
        <>

            <Toast ref={toast} />
            <Button type="button"
                    icon="fad fa-download"
                    tooltip={"Select a layer & download the data that overlap with "+props.data.rowData.title}
                    tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                    className="p-mr-2"
                    onClick={(e) => op.current.toggle(e)} />
            <OverlayPanel ref={op}>
                <DataTable
                    value={props.data.layers}
                    dataKey="id"
                    className="p-datatable-customers p-datatable-sm"
                    autoLayout={true}
                    stripedRows
                    loading={loading}
                >

                    <Column
                        field="title"
                        header="Layer"
                    />
                    <Column
                        field="action"
                        body={(e)=>overlayActionBodyTemplate(e,props.data.rowData)}
                    />
                </DataTable>
            </OverlayPanel>

            <Menu model={items} popup ref={navigationMenu} />
            <Button icon="fad fa-map-marked-alt"
                    tooltip={"Navigation Actions"}
                    tooltipOptions={{ className: 'green-tooltip', position: 'top' }}
                    className="p-button-success p-mr-2"
                    onClick={(event) => navigationMenu.current.toggle(event)}/>


            <Button type="button"
                    icon="fad fa-times"
                    tooltip={"Remove "+props.data.rowData.title+""}
                    tooltipOptions={{ className: 'red-tooltip', position: 'top' }}
                    className="p-button-danger"
                    onClick={(e) => removeCustomPolygon(e,props.data.rowData)}
            />




        </>
    );
}
